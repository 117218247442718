<template>
  <div class="month-popup">
    <div class="card">
      <div class="card-body">
        <div class="months" v-if="months">
          <slot name="prepend"></slot>
          <b-row :key="year" class="year" v-for="(group, year) in months">
            <b-col cols="auto" class="pr-0">
              <div class="year-title">{{year}}</div>
            </b-col>
            <b-col>
              <div :key="item[3]" class="month" v-for="item in group">
                <b-link :to="goto(item)" class="month-link" v-if="!item.hidden">
                  {{item.month}}月
                </b-link>
                <b-link @click="goMonths(item)" class="month-link disabled" v-else>
                  {{item.month}}月
                </b-link>
              </div>
            </b-col>
          </b-row>
          <slot name="append"></slot>
        </div>
        <empty icon="calendar-alt" v-else>
          <p>还没有月份哦</p>
          <b-btn :to="'/books/' + book.bookId + '/edit/' + $day().format('YYYYMM')"
                 variant="primary">
            <fa icon="plus-circle"/>
            补写内容
          </b-btn>
        </empty>
      </div>

      <bottom-bar :items="navs" nav style="position: relative;"/>
    </div>
    <div @click="close()" class="dimmer"></div>
  </div>
</template>

<script>
export default {
  name: 'monthPopup',
  props: {
    months: {
      required: true
    },
    book: {
      type: Object,
      required: true
    },
    routeName: String,
    complete: Boolean
  },
  computed: {
    navs() {
      return [
        {
          title: '关闭',
          icon: 'times',
          divide: true,
          onClick: () => this.close(),
          maxWidth: '4em',
          style: {
            'margin-right': 'auto'
          }
        }, {
          icon: 'question-circle',
          title: '做书教程',
          tooltip: '操作太复杂？先看看教程吧',
          show: !this.isPartnerUser,
          href: 'https://www.wolai.com/xinshu/dteqtDmk7Wd7JoJ6BpSQ8',
          target: '_blank'
        }, {
          title: this.book.shared ? '取消分享' : '分享',
          icon: this.book.shared ? 'lock' : 'share',
          onClick: () => {
            this.setVisibility(!this.book.shared)
          },
          maxWidth: '6em',
          show: this.book.auth === 'edit'
        }, {
          title: '更新内容',
          icon: 'history',
          show: this.book.canUpdate && !this.isAgentUser,
          maxWidth: '6em',
          to: '/books/' + this.book.bookId + '/update'
        }, {
          title: '返回书架',
          icon: 'books',
          exact: true,
          show: this.hasLogin && !this.isPartnerUser,
          maxWidth: '6em',
          to: '/books'
        }, {
          title: '返回封面',
          icon: 'book',
          exact: true,
          maxWidth: '6em',
          show: !!this.activeMonth,
          to: '..'
        }
      ]
    },
    activeYear() {
      return this.$route.params.month ? this.$route.params.month.slice(0, -2) : ''
    },
    activeMonth() {
      return this.$route.params.month ? parseInt(this.$route.params.month.slice(-2)) : ''
    }
  },
  watch: {
    $route: {
      handler() {
        this.close()
      },
      deep: true
    },
    show(val) {
      if (val) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.month-link.active')
          el && el.scrollIntoView()
        })
      }
    }
  },
  methods: {
    async goMonths() {
      const confirmed = await this.$dialog.confirm({
        title: '恢复隐藏内容',
        content: '该月份已被隐藏，是否前往选择月份页面开启',
        okTitle: '前往开启'
      })
      if (confirmed) {
        this.$router.push('/books/' + this.$route.params.bookId + '/months')
      }
    },
    close() {
      this.$emit('close')
    },
    goto(item) {
      return {
        name: this.routeName || this.$route.name,
        params: {
          ...this.$route.params,
          month: item.route
        }
      }
    },
    setVisibility(val) {
      return this.$req.post('/api/library/edit_share_book', {
        bookId: this.book.id,
        bookType: this.book.bookType,
        shared: !!val
      }).then(() => {
        if (val) {
          if (this.isAgentUser) {
            this.$alert.success('已将作品设置为公开，使用分享功能将作品分享给好友吧')
          } else {
            this.$router.push('/book-share/' + this.$route.params.bookId)
            return
          }
        } else {
          this.$alert.success('已将作品设为私密')
        }
        this.$emit('update:book', {...this.book, shared: !!val})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.month-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 25;

  .card {
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 0;
    right: 0;
    border: 0;
    border-radius: 0;
    z-index: 10;
    max-height: 100%;

    .card-body {
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }

    .card-footer {
      background-color: $white;
    }
  }

  .months {
    position: relative;
    flex-grow: 1;
    line-height: 2.25;

    &:after {
      content: '';
      position: absolute;
      display: block;
      border-left: 1px solid $hr-border-color;
      top: 0;
      bottom: -$card-columns-gap;
      left: .85rem;
      z-index: 0;
    }
  }

  .month-link {
    float: left;
    width: 16.667%;
    display: block;
    text-align: center;
    border: 1px solid transparent;
    border-radius: $border-radius;
    color: $body-color;

    &.active {
      color: $primary;
      border-color: $primary;
    }

    &.disabled {
      color: $text-muted;
      text-decoration: line-through;
      pointer-events: all;
    }
  }

  .year {
    position: relative;
    overflow: hidden;

    .year-title {
      font-weight: bold;
      width: 4em;
      text-align: center;
      background-color: #fff;
      position: relative;
      border-radius: $border-radius;
      border: 1px solid $hr-border-color;
      z-index: 1;
    }
  }

  .close-btn {
    color: $body-color;
  }

  .dimmer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, .3);
  }
}
</style>
