<template>
  <div class="catalog-nav">
    <div class="content-wrap">
      <div class="months">
        <b-link class="item"
                v-for="(item, i) in months"
                :key="item.route"
                :title="item.title"
                :to="route(item)">
          <b-row no-gutters>
            <b-col class="number" cols="auto">
              <span class="text-muted">{{startIndex + i + 1}}.</span>
            </b-col>
            <b-col class="title">{{item.title}}</b-col>
            <b-col cols="auto">
              <span class="text-muted ml-2">{{item.pageNo}}</span>
            </b-col>
          </b-row>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'catalogNav',
  props: {
    months: {
      required: true
    },
    startIndex: {
      default: 0,
      type: Number
    },
    routeName: String,
    complete: Boolean
  },
  computed: {
    activeMonth() {
      return this.$route.params.month
    }
  },
  watch: {
    $route: {
      handler() {
        this.$bvModal.hide('catalog')
      },
      deep: true
    }
  },
  methods: {
    route(item) {
      return '/books/' + this.$route.params.bookId + '/months/' + item.route
    }
  }
}
</script>

<style scoped lang="scss">
$width: 72px;
$offset: .25em;

.number {
  min-width: 2em;
  padding-right: .5em;
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  overflow: hidden;
}

.content-wrap {
  .months {
    .item:last-child {
      border-bottom: none;
    }

    .item {
      display: block;
      padding: 1rem 0;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      color: $body-color;
    }

    .active {
      color: $primary;
    }
  }
}
</style>
