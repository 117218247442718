<template>
  <div class="catalog-popup">
    <div class="card">
      <div class="card-body">
        <virtual-list :size="44" :start="startIndex" :remain="12" class="months"
                      v-if="months.length > 0">
          <b-link class="item" v-for="(item, i) in months" :key="item.route" :to="getRoute(item)">
            <span class="text-muted">{{i + 1}}.</span>
            {{item.title}}
            <span class="page-no">{{item.pageNo}}</span>
          </b-link>
        </virtual-list>
        <div v-else>
          <empty>您的书里还没有内容哦</empty>
        </div>
      </div>

      <bottom-bar :items="navs" nav style="position: relative;"/>
    </div>
    <div @click="close()" class="dimmer"></div>
  </div>
</template>

<script>
import VirtualList from 'vue-virtual-scroll-list'
import copyText from 'clipboard-copy'

export default {
  name: 'catalogPopup',
  components: {
    VirtualList
  },
  props: {
    months: {
      required: true
    },
    book: {
      type: Object,
      required: true
    },
    routeName: String,
    complete: Boolean
  },
  computed: {
    startIndex() {
      return this.months.findIndex(i => i.route === this.activeMonth) - 4
    },
    navs() {
      return [
        {
          title: '关闭',
          icon: 'times',
          divide: true,
          onClick: () => this.close(),
          maxWidth: '5em',
          style: {
            'margin-right': 'auto'
          }
        }, {
          title: '编辑目录',
          icon: 'pencil',
          maxWidth: '6em',
          show: this.book.editable,
          to: '/books/' + this.book.bookId + '/catalog'
        }, {
          title: '分享作品',
          icon: 'share',
          maxWidth: '6em',
          onClick: () => this.goShare()
        }, {
          title: '更新内容',
          icon: 'history',
          show: this.book.canUpdate && !this.isAgentUser,
          maxWidth: '6em',
          onClick: () => this.goUpdate()
        }, {
          title: '返回封面',
          icon: 'book',
          exact: true,
          maxWidth: '6em',
          show: !!this.activeMonth,
          to: '..'
        }, {
          title: '返回书架',
          icon: 'books',
          exact: true,
          show: this.hasLogin && !this.isPartnerUser,
          maxWidth: '6em',
          to: '/books'
        }
      ]
    },
    activeMonth() {
      return this.$route.params.month
    }
  },
  watch: {
    $route: {
      handler() {
        this.close()
      },
      deep: true
    }
  },
  methods: {
    async goShare() {
      if (window.isWechat) {
        this.$dialog.confirm({
          title: '分享作品',
          content: '点击右上角 <b><i class="fa fa-ellipsis-h"></i></b> 进行分享',
          okTitle: '好的',
          okOnly: true
        })
      } else {
        const link = location.href
        await copyText(link)
        this.$dialog.confirm({
          title: '分享作品',
          content: '链接已复制，请发送给好友',
          okTitle: '好的',
          okOnly: true
        })
      }
    },
    close() {
      this.$emit('close')
    },
    getRoute(item) {
      return {
        name: this.routeName || this.$route.name,
        params: {
          ...this.$route.params,
          month: item.route
        }
      }
    },
    goUpdate() {
      if (this.book.sourceSite === 'weixinmp') {
        this.$dialog.confirm({
          title: '更新文章',
          content: '更新微信公众号内容需要在电脑端进行操作',
          okTitle: '好的',
          okOnly: true
        })
      } else {
        this.$req.post('/blogbook/crawler/crawl/', {sid: this.book.sid}).then(() => {
          this.$alert.success('已请求更新！请稍后刷新查看')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.catalog-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 25;

  .card {
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 0;
    right: 0;
    border: 0;
    border-radius: 0;
    z-index: 10;
    max-height: 100%;

    .card-body {
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }

    .card-footer {
      background-color: $white;
    }
  }

  .months {
    position: relative;
    flex-grow: 1;

    .item {
      display: block;
      padding: 10px 0;
      line-height: 24px;
      border-bottom: 1px solid $hr-border-color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 2.5em;
      position: relative;

      .page-no {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        line-height: 1;
        height: 1em;
        margin: auto;
        color: $text-muted;
        text-align: right;
      }

      &:not(.active) {
        color: inherit;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .dimmer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, .3);
  }
}
</style>
