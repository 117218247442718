<template>
  <div class="page placeholder">
    <div class="page-inner">
      <div class="mb-5">
        <p class="text-left">
          <ske type="icon"></ske>
          <ske width="8em"></ske>
        </p>
        <ske block></ske>
        <ske block></ske>
      </div>
      <div class="mb-5">
        <p class="text-left">
          <ske type="icon"></ske>
          <ske width="8em"></ske>
        </p>
        <b-row>
          <b-col>
            <ske type="square"></ske>
          </b-col>

          <b-col>
            <p>
              <ske block></ske>
              <ske block></ske>
            </p>
            <ske block></ske>
            <ske block></ske>
          </b-col>
        </b-row>
      </div>
      <h2 class="mt-5" v-if="$slots.default">
        <slot></slot>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'placeholderPage'
}
</script>

<style lang="scss" scoped>
  .page {
    text-align: center;
    color: $text-muted;
    height: auto;

    .page-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 42px 56px;
    }

    &:after {
      display: block;
      content: '';
      padding-bottom: 142%;
    }
  }
</style>
