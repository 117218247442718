<template>
  <transition name="fade">
    <div class="floating-guide" v-if="active" @click="ok">
      <img :src="arrow" alt="" class="arrow" v-if="hasSubscribed">
      <img :src="poster" alt="" class="poster">

      <transition name="fade">
        <img :src="button" alt="我知道了" v-if="canClose" class="ok">
      </transition>

      <footer v-if="!isPartnerUser">也可以通过「心书」公众号随时找到作品</footer>
    </div>
  </transition>
</template>

<script>
import { wait } from '@/utils/wait-for'

export default {
  name: 'floatingGuide',
  data() {
    return {
      button: 'https://img.xinshu.me/upload/66ce99a34590419fba6a39ea192c14e8',
      arrow: 'https://img.xinshu.me/upload/de11959f64b74bcfb32ec33bfd4f029f',
      active: false,
      canClose: false
    }
  },
  computed: {
    poster() {
      if (!this.isPartnerUser && this.user && !this.hasSubscribed) {
        // 未关注弹出公众号二维码
        return 'https://img.xinshu.me/upload/9d749651e0b74160a095841a8c7211dd'
      }
      return 'https://img.xinshu.me/upload/6aaa38bbb5a749c4a3f43e1fbc0f4048'
    }
  },
  async created() {
    if (this.isWechat && !this.isMiniProgram && !this.$ls.get('guide.floating')) {
      await Promise.all([
        this.loadImage(this.poster),
        this.loadImage(this.button),
        this.loadImage(this.arrow)
      ])
      await wait(200)
      this.active = true
      await wait(1000)
      this.canClose = true
      return
    }
    this.$destroy()
  },
  methods: {
    ok() {
      this.$ls.set('guide.floating', 1)
      this.active = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .floating-guide {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    padding: 1rem;
    background-color: rgba(0, 0, 0, .8);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .arrow {
      position: absolute;
      width: 12%;
      right: 1rem;
      top: 1rem;
    }

    footer {
      color: #fff;
    }

    .poster {
      width: 100%;
      max-width: 560px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .ok {
      width: 172px;
      height: 66px;
      cursor: pointer;
    }
  }
</style>
