<template>
  <div class="inner">
    <template v-if="loading">
      <div class="pages" v-auto-scale :key="'placeholder'">
        <insert-page :book="book" :date="$route.params.month" v-if="hasInsertPage"/>
        <placeholder-page/>
      </div>
    </template>

    <template v-else-if="!bookPages.length">
      <b-card class="text-center">
        <img src="https://static.weixinshu.com/assets/images/deer/wail.png" style="width: 240px;">
        <p>当前月份没有内容哦，请选择其他月份</p>
        <div v-if="book.editable">
          或前往
          <b-link :to="'../restore/' + $route.params.month">恢复内容</b-link>
        </div>
      </b-card>
    </template>

    <div class="pages" v-auto-scale v-else>
      <insert-page :book="book" :date="$route.params.month" v-if="hasInsertPage">
        <div class="mask" @click="$parent.goEdit('insert')">点击更换插页</div>
      </insert-page>
      <content-page v-for="page in bookPages"
                    :book="book" :key="'page' + page.pageNo"
                    :date="$route.params.month" :page="page"/>
    </div>

    <b-row class="navigate-buttons" v-if="months">
      <b-col class="pr-0" v-if="prevMonth">
        <b-btn :to="'./' + prevMonth.route" class="w-100">
          <fa icon="angle-left"/>
          {{prevMonth.value}}
        </b-btn>
      </b-col>
      <b-col class="pr-0" cols="auto">
        <b-btn :to="'/books/' + bookId" exact class="w-100">
          <fa icon="angle-left" v-if="!prevMonth"/>
          返回封面
        </b-btn>
      </b-col>
      <b-col v-if="nextMonth">
        <b-btn :to="'./' + nextMonth.route" class="w-100">
          {{nextMonth.value}}
          <fa icon="angle-right"/>
        </b-btn>
      </b-col>
      <b-col v-else>
        <b-button class="w-100" disabled>
          本书完
          <fa icon="paragraph"/>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { chain, filter, get, isEmpty } from 'lodash'

import route from '@/mixins/route-data'
import inherits from '@/mixins/inherits'

export default {
  name: 'inner',
  mixins: [
    route('bookPages'),
    inherits(['book', 'bookId', 'months'])
  ],
  components: {
    InsertPage: require('./InsertPage').default,
    ContentPage: require('./ContentPage').default,
    PlaceholderPage: require('./PlaceholderPage').default
  },
  beforeRouteUpdate(to, from, next) {
    this.$ss.removeItem('book.inner.' + this.$route.params.month)
    next()
  },
  computed: {
    scrollPosKey() {
      return ['scroll', this.bookId, this.$route.params.month].join('.')
    },
    hasBook() {
      return !isEmpty(this.book)
    },
    hasInsertPage() {
      return get(this.book, 'style.insertPage') && get(this.book, 'style.insertPage') !== 'blank'
    },
    prevMonth() {
      if (this.book.bookType === 'blogbook' && this.months.length > 0) {
        const idx = this.months.findIndex(item => item.route === this.$route.params.month)
        return idx === 0 ? null : this.months[idx - 1]
      }
      return this.months.prev && this.months.prev(this.$route.params.month)
    },
    nextMonth() {
      if (this.book.bookType === 'blogbook' && this.months.length > 0) {
        const idx = this.months.findIndex(item => item.route === this.$route.params.month)
        return idx === (this.months.length - 1) ? '' : this.months[idx + 1]
      }
      return this.months.next && this.months.next(this.$route.params.month)
    }
  },
  data() {
    return {
      pageEls: [],
      template: '',
      innerHTML: ''
    }
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
    document.removeEventListener('click', this.onClick)
  },
  async mounted() {
    this.loadFont('Songti SC')
    if (this.book.type === 'A5-3') {
      this.loadFont('Snell Bold BT')
    }
    if (['A4-1', 'A4-1-child', 'A5-5-child', 'A5-4'].includes(this.book.type)) {
      this.loadFont('Snell Roundhand')
    }
  },
  methods: {
    onLoad() {
      if (Array.isArray(this.months)) {
        const index = this.months.findIndex(i => i.route === this.$route.params.month)
        const offset = chain(this.months).slice(0, index).sumBy('pageCount').value() || 0
        this.bookPages.forEach((page, index) => {
          page.pageNo = offset + index + 1
        })
      }

      setTimeout(() => {
        const scrollY = this.$ss.getItem(this.scrollPosKey)
        if (scrollY) {
          window.scrollTo(0, scrollY)
        }
        this.pageEls = this.$el.querySelectorAll('.page.content')
        this.onScroll()
        if (this.book.editable) {
          document.addEventListener('click', this.onClick, false)
        }
        document.addEventListener('scroll', this.onScroll, false)
      })
    },
    async loadFont(fontName) {
      const fonts = {
        'Songti SC': 'https://static.weixinshu.com/fonts/STSongti-SC-Regular.ttf',
        'Snell Roundhand': 'https://static.weixinshu.com/fonts/Snell-Roundhand.ttf',
        'Snell Bold BT': 'https://static.weixinshu.com/fonts/Snell-Bold-BT.ttf'
      }

      if ('FontFace' in window && 'fonts' in document && !document.fonts.check('1em ' + fontName)) {
        const fontface = new FontFace(
          fontName,
          'url(' + fonts[fontName] + ')'
        )
        const font = await fontface.load()
        document.fonts.add(font)
      }
    },
    async onClick(e) {
      if (!e.target) {
        return
      }
      const msgId = e.target?.dataset?.msgId || e.target.closest('[data-msg-id]')?.dataset?.msgId

      if (!msgId) {
        return
      }

      const confirmed = await this.$dialog.confirm({
        title: '编辑内容',
        content: '是否要前往编辑本条内容',
        okTitle: '前往编辑'
      })
      if (!confirmed) {
        return
      }
      const {month, bookId} = this.$route.params
      if (this.book.bookType === 'blogbook') {
        this.$router.push('/books/' + bookId + '/editblogbook/' + month + '?msgId=' + msgId)
      } else {
        this.$router.push('/books/' + bookId + '/edit/' + month + '?msgId=' + msgId)
      }
    },
    onScroll() {
      this.$ss.setItem(this.scrollPosKey, window.scrollY)

      const pageEls = this.pageEls

      if (!pageEls || pageEls.length === 0) {
        return
      }

      const unloadedEls = filter(pageEls, el => !/loaded/.test(el.className))

      if (unloadedEls.length === 0) {
        return
      }

      for (let i = 0; i < unloadedEls.length; i++) {
        const pageEl = unloadedEls[i]
        const elementInViewport = isElementVisible(pageEl)
        if (elementInViewport) {
          this.$broadcast('pageLoad', pageEl.dataset.id)
        }
      }

      function isElementVisible(el) {
        // From https://gist.github.com/davidtheclark/5515733
        const rect = el.getBoundingClientRect()
        // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth)

        // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
        const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0)
        const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0)

        return (vertInView && horInView)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
