<template>
  <div class="month-nav">
    <slot name="prepend"></slot>
    <div class="year" v-for="(group, year) in months" :key="year">
      <b-link class="year-title" @click="toggleYear(year)"
              :class="{active: year === activeYear, disabled: isYearDisabled(year)}">
        {{year}}
      </b-link>
      <transition name="expand">
        <div class="months" v-if="openYear === year">
          <template v-for="item in group">
            <li class="disabled" @click="goMonths(item)" v-if="item.hidden" :key="item.route">
              <a>{{item.month}}月</a>
            </li>
            <router-link :to="goto(item)" tag="li" v-else :key="item.route">
              <a>{{item.month}}月</a>
            </router-link>
          </template>
          <li v-if="year === lastYear && complete">
            <a :href="updateUrl" @click.prevent="preUpdate">待续</a>
          </li>
        </div>
      </transition>
    </div>
    <slot name="append"></slot>
  </div>
</template>

<script>
export default {
  name: 'monthNav',
  props: {
    months: {
      required: true
    },
    routeName: String,
    complete: Boolean
  },
  computed: {
    activeYear() {
      return this.$route.params.month ? this.$route.params.month.slice(0, -2) : ''
    },
    activeMonth() {
      return this.$route.params.month ? parseInt(this.$route.params.month.slice(-2)) : ''
    },
    lastYear() {
      const years = Object.keys(this.months)
      return years[years.length - 1]
    },
    book() {
      return this.$parent.book
    },
    updateUrl() {
      return '/books/' + this.$route.params.bookId + '/update'
    }
  },
  data() {
    return {
      openYear: ''
    }
  },
  created() {
    this.openYear = this.activeYear
  },
  watch: {
    $route() {
      this.openYear = this.activeYear
    }
  },
  methods: {
    isYearDisabled(year) {
      if (!this.complete) {
        return false
      }
      return this.months[year].filter(item => !item.hidden).length === 0
    },
    async toggleYear(year) {
      if (this.isYearDisabled(year)) {
        const confirmed = await this.$dialog.confirm({
          title: '恢复隐藏内容',
          content: '该年份已被隐藏，是否前往选择月份页面开启',
          okTitle: '前往开启',
          cancelTitle: '算了'
        })
        if (confirmed) {
          this.$router.push('/books/' + this.$route.params.bookId + '/months')
        }
        return
      }

      if (this.openYear === year) {
        this.openYear = ''
      } else {
        this.openYear = year
      }
    },
    goto(item) {
      return {
        name: this.routeName || this.$route.name,
        params: {
          bookId: this.$route.params.bookId,
          month: item.route
        }
      }
    },
    async goMonths(item) {
      const confirmed = await this.$dialog.confirm({
        title: '恢复隐藏内容',
        content: item.value + '已被隐藏，是否前往选择月份页面开启',
        okTitle: '前往开启',
        cancelTitle: '算了'
      })
      if (confirmed) {
        this.$router.push('/books/' + this.$route.params.bookId + '/months')
      }
    },
    async preUpdate() {
      const params = {
        title: '更新提示',
        content: '好久没有新内容啦，不要等记忆远走了才去追忆，快去更新吧～',
        okTitle: '前往更新内容',
        cancelTitle: '算了'
      }
      const confirmed = await this.$dialog.confirm(params)
      if (!confirmed) {
        return
      }
      this.$router.push(this.updateUrl)
    }
  }
}
</script>

<style scoped lang="scss">
$width: 68px;
$offset: 4px;
.month-nav {
  width: $width + 4px;
  padding-left: 4px;
  margin-left: -$width - 4px;
  text-align: right;
  z-index: 5;
  padding-right: $grid-gutter-width-base / 2;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }

  .year {
    margin-bottom: 2 * $offset;
  }

  .year-title {
    display: block;
    background-color: #ccc;
    line-height: 1;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    color: #fff;
    font-size: 16px;
    transition: .3s;
    border: 1px solid $hr-border-color;

    &:hover {
      margin-left: -$offset;
      background-color: $primary;
    }

    &.disabled {
      opacity: .6;
      text-decoration: line-through;
      pointer-events: all;

      + .months {
        display: none !important;
      }
    }

    &.open {
      opacity: .6;
      background-color: $primary;
      margin-left: -$offset;
    }

    &.active {
      margin-left: -$offset;
      opacity: 1;
      background-color: $primary;
    }
  }

  .months {
    margin: 0;
    padding-left: 0;
    list-style: none;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      border-right: 1px solid $hr-border-color;
    }

    li {
      border-right: 4px solid #ccc;
      line-height: 2;
      transition: opacity .3s;

      &:hover {
        opacity: .6;
      }

      &.disabled {
        opacity: .6;
        text-decoration: line-through;
        pointer-events: all;
      }

      &.active {
        border-color: $primary;
        color: $primary;

        a {
          color: $primary;
        }
      }

      a {
        padding-right: .5em;
        display: block;
        color: $body-color;
        transition: .3s;
      }
    }
  }

  .expand-enter-active, .expand-leave-active {
    transition: max-height .3s;
  }

  .expand-enter-to, .expand-leave {
    max-height: 12 * 32px;
  }

  .expand-enter, .expand-leave-to {
    max-height: 0;
  }
}
</style>
