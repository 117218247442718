<template>
  <div class="side-menu">
    <div class="mb-2" v-for="item in menuItems" :key="item.title">
      <hr v-if="item.hr === 'top'">
      <b-btn v-bind="props(item)" v-on="events(item)" :title="item.tooltip" v-b-tooltip.left>
        <div class="dot" v-if="item.dotted"></div>
        <fa :icon="item.icon" fw v-if="item.icon"/>
        {{item.text || item.title}}
      </b-btn>
      <hr v-if="item.hr === 'bottom'">
    </div>
  </div>
</template>

<script>
export default {
  name: 'sideMenu',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    menuItems() {
      return this.items.filter(i => i.show !== false && i.exclusive !== 'nav').reverse()
    }
  },
  methods: {
    events(item) {
      if (item.onClick) {
        return {
          click: item.onClick
        }
      }
    },
    props(item) {
      const bind = {
        class: []
      }

      if (item.variant) {
        bind.variant = item.variant
      }

      if (item.disabled) {
        bind.disabled = true
      }

      if (item.replace) {
        bind.replace = true
      }

      if (item.style) {
        bind.style = Object.assign({}, bind.style, item.style)
      }

      if (item.active) {
        bind.active = true
      }

      if (item.show === false) {
        bind.hidden = true
      }

      if (item.href) {
        bind.href = item.href
        if (item.target) {
          bind.target = item.target
        }
      }

      if (item.to) {
        bind.to = item.to
        if (item.to === '/' || item.exact) {
          bind.exact = true
        }
      }

      return bind
    }
  }
}
</script>

<style scoped lang="scss">
.side-menu {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }

  position: absolute;
  min-width: 7.5em;
  margin-left: .75em;
  text-align: left;
  z-index: 5;

  .btn {
    display: block;
    width: 100%;
    box-shadow: $shadow-base;
    position: relative;
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background-color: $primary;
    position: absolute;
    top: 2px;
    right: 2px;
  }
}
</style>
