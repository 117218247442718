<template>
  <div :class="[typeset, section, {editable: book.editable}]" class="book">
    <h4 class="book-title text-center mb-3 d-none d-sm-block">
      <template v-if="book.title">{{book.title}} / 共 {{pageNum}} 页</template>
      <ske width="10em" v-else/>
    </h4>

    <template v-if="book">
      <floating-guide v-if="book.editable"/>

      <template v-if="isSmallScreen">
        <template v-if="popup && months">
          <catalog-popup :months="months" :book="book" complete
                         route-name="book.inner"
                         @close="popup = false"
                         v-if="book.bookType === 'blogbook'"/>
          <month-popup :months="months" :book.sync="book" complete
                       route-name="book.inner"
                       @close="popup = false"
                       v-else/>
        </template>
        <!-- 安卓及 iOS App 中隐藏底部菜单 -->
        <bottom-bar :items="navs" nav v-if="showBottomNav"/>
      </template>

      <template v-else>
        <div class="side-menu float-right">
          <side-menu :items="navs" v-headroom="'1em'"/>
        </div>

        <template v-if="months">
          <div class="side-menu float-left" v-if="bookParams.bookType === 'blogbook'">
            <div class="catalog" v-headroom="'1em'">
              <div class="mb-2">
                <b-btn disabled v-if="$route.name === 'book'">
                  <fa icon="book" fw/>
                  封面
                </b-btn>
                <b-btn to=".." exact v-else>
                  <fa icon="book" fw/>
                  返回封面
                </b-btn>
              </div>
              <b-btn v-b-modal="'catalog'" v-if="months.length > 0">
                <fa icon="list" fw/>
                查看目录
              </b-btn>
            </div>
            <b-modal id="catalog" title="目录" :hide-footer="months.length <= catalog.pageSize">
              <catalog-nav :months="catalogList"
                           :start-index="(catalog.currentPage - 1) * catalog.pageSize"/>
              <template slot="modal-footer">
                <div>
                  <b-btn :disabled="catalog.currentPage <= 1" @click="catalog.currentPage = 1">
                    «
                  </b-btn>
                  <b-btn :disabled="catalog.currentPage <= 1" @click="catalog.currentPage--">
                    上一页
                  </b-btn>
                </div>

                <span>{{catalog.currentPage}} / {{catalog.totalPage}}</span>

                <div>
                  <b-btn :disabled="catalog.currentPage >= catalog.totalPage"
                         @click="catalog.currentPage++">
                    下一页
                  </b-btn>
                  <b-btn :disabled="catalog.currentPage >= catalog.totalPage"
                         @click="catalog.currentPage = catalog.totalPage">
                    »
                  </b-btn>
                </div>
              </template>
            </b-modal>
          </div>
          <div class="side-menu float-left" v-else>
            <month-nav :complete="book.editable" :months="months" route-name="book.inner"
                       v-headroom="'1em'">
              <div class="year mb-2" slot="prepend">
                <b-link :to="'/books/' + bookId" class="year-title" exact
                        :class="{active: !$route.params.month}">封面
                </b-link>
              </div>
            </month-nav>
          </div>
        </template>
      </template>
    </template>

    <template v-if="loading">
      <loading v-if="!book.id"/>
      <div class="pages" v-auto-scale :key="'placeholder'" v-else>
        <placeholder-page>
          <template v-if="book.needRepublish">正在重新排版</template>
        </placeholder-page>
        <placeholder-page/>
      </div>
    </template>

    <template v-else>
      <router-view v-if="$route.params.month"/>

      <template v-else>
        <div class="pages" v-auto-scale>
          <cover-page :book="book">
            <div class="mask" @click="goEdit('cover')" v-if="book.editable">
              <div>点击修改封面</div>
              <div class="small">可更换封面图片，修改书名作者等</div>
            </div>
          </cover-page>
          <title-page :book="book"/>
          <copyright-page :book="book">
            <div class="mask" @click="goEdit('copyright')" v-if="book.editable">点击编辑版权页</div>
          </copyright-page>
          <acknowledgement-page :book="book" v-if="book.acknowledgement">
            <div class="mask" @click="goEdit('acknowledgement')" v-if="book.editable">点击编辑致谢</div>
          </acknowledgement-page>
          <div class="page add" @click="goEdit('acknowledgement')" v-else-if="book.editable">
            <fa icon="plus-circle"/>
            点击添加致谢
          </div>
          <preface-page :book="book" v-if="book.preface">
            <div class="mask" @click="goEdit('preface')" v-if="book.editable">点击编辑序言</div>
          </preface-page>
          <div class="page add" @click="goEdit('preface')" v-else-if="book.editable">
            <fa icon="plus-circle"/>
            点击添加序言
          </div>
          <catalog-page v-for="page in catalog.pages"
                        :book="book"
                        :key="page.pageId"
                        :content="page.content"
                        :isFirst="page.pageId === 0"/>
        </div>

        <footer>
          <template v-if="book.bookType !== 'blogbook'">
            <b-btn size="lg" :to="'months/' + firstMonth.route" append block variant="primary"
                   v-if="firstMonth">
              进入内页
              {{firstMonth.value}}
              <fa icon="angle-right" fw class="animation-left-right"/>
            </b-btn>
            <div class="text-center" style="font-size: 1.25em;" v-else>
              <div v-if="isImporting">
                <fa icon="cog" spin/>
                内容正在导入
              </div>
              <div class="w-100" disabled v-else>
                当前作品没有内容哦
                <b-link :to="'/books/' + bookId + '/edit/' + editMonth" exact>前往补写</b-link>
              </div>
            </div>
          </template>
          <template v-else>
            <b-btn size="lg" :to="'months/' + firstMonth.route" append block variant="primary"
                   v-if="firstMonth">
              进入内页
              {{firstMonth.value}}
              <fa icon="angle-right" fw class="animation-left-right"/>
            </b-btn>
          </template>
        </footer>

        <scroll-tip>
          <fa icon="chevron-down" class="animation-up-down" fw/>
          下拉查看更多内容
        </scroll-tip>
      </template>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'
import copyText from 'clipboard-copy'

import { isAlbum, validateBookCode } from '@/models/book'
import { isVerGreaterThan } from '@/utils/utils'
import { configShare } from '@/modules/wechat'

export default {
  name: 'book',
  title: '作品预览',
  mixins: [
    routeData('book', {relativeParam: 'bookId', query: {agent: true}}),
    require('@/mixins/book-utils').default,
    require('@/mixins/reload-needed').default
  ],
  components: {
    SideMenu: require('@/components/SideMenu').default,
    CoverPage: require('./Book/CoverPage').default,
    TitlePage: require('./Book/TitlePage').default,
    CopyrightPage: require('./Book/CopyrightPage').default,
    PrefacePage: require('./Book/PrefacePage').default,
    AcknowledgementPage: require('./Book/AcknowledgementPage').default,
    CatalogPage: require('./Book/CatalogPage').default,
    PlaceholderPage: require('./Book/PlaceholderPage').default,

    FloatingGuide: require('@/components/FloatingGuide').default,
    MonthNav: require('@/components/MonthNav').default,
    MonthPopup: require('@/components/MonthPopup').default,
    CatalogNav: require('@/components/CatalogNav').default,
    CatalogPopup: require('@/components/CatalogPopup').default
  },
  async beforeRouteEnter(to, from, next) {
    const bookId = to.params.bookId
    const {bookCode, bookType, id} = to.meta?.bookParams || {}
    if (bookCode) {
      try {
        await validateBookCode(bookId)
      } catch (err) {
        console.error(err.message)
      }
    }
    if (bookType === 'star-wbbook') {
      return location.replace('https://weiboshu.com/design/' + id + '/preview')
    }
    if (isAlbum(bookType)) {
      return next('/albums/' + id)
    }
    next()
  },
  data() {
    return {
      months: {},
      bookPages: 0,
      activeMenu: 0,
      popup: false,
      catalog: {
        pages: [],
        currentPage: 1,
        pageSize: 14,
        totalPage: 0
      }
    }
  },
  watch: {
    '$route.params.month'() {
      this.popup = false
    }
  },
  computed: {
    navs() {
      const {bookType, agentOpenid, editable, isOwner} = this.book

      if (!bookType) {
        return []
      }

      let editNavs = []
      if (bookType !== 'blogbook') {
        const hasContent = this.book.chapters
        editNavs = [
          {
            icon: hasContent ? 'pencil' : 'plus-circle',
            title: hasContent ? '编辑内容' : '补写内容',
            tooltip: hasContent ? '对作品内容进行添加、修改' : '为作品添加内容',
            to: '/books/' + this.bookId + '/edit/' + this.editMonth,
            show: editable,
            hr: !this.isPartnerUser && isOwner
          },
          {
            icon: 'calendar-alt',
            disabled: !hasContent,
            title: '筛选月份',
            tooltip: '显示或隐藏作品月份内容',
            exact: true,
            to: '/books/' + this.bookId + '/months',
            show: editable
          }
        ]
      } else {
        const hasContent = this.book.chapters[0].current.length > 0
        const showCreate = !hasContent || !this.$route.params.month
        editNavs = [
          {
            icon: showCreate ? 'plus-circle' : 'pencil',
            title: showCreate ? '新建文章' : '编辑本篇',
            show: editable,
            tooltip: showCreate ? '为作品添加新文章' : '编辑本篇文章内容',
            to: showCreate ? `/books/${this.bookId}/catalog?write=1` : `/books/${this.bookId}/editblogbook/${this.editMonth}`
          }, {
            icon: 'list-ol',
            title: '编辑目录',
            show: editable && hasContent,
            tooltip: '修改文章顺序、批量删除文章',
            to: '/books/' + this.bookId + '/catalog'
          }
        ]
      }

      return [
        {
          icon: 'bars',
          title: '目录',
          maxWidth: '4em',
          divide: true,
          exclusive: 'nav',
          onClick: () => {
            this.popup = true
          }
        },
        {
          icon: 'question-circle',
          title: '做书教程',
          tooltip: '操作太复杂？先看看教程吧',
          exclusive: 'menu',
          show: !this.isPartnerUser,
          href: 'https://www.wolai.com/xinshu/dteqtDmk7Wd7JoJ6BpSQ8',
          target: '_blank'
        },
        {
          icon: 'history',
          title: this.book.updating ? '正在更新' : '更新内容',
          tooltip: '将社交媒体内容同步到作品中',
          show: !this.isPartnerUser && this.book.canUpdate,
          onClick: () => this.goUpdate()
        },
        {
          icon: 'share-alt',
          title: '分享作品',
          variant: 'warning',
          tooltip: '将您的作品分享给好友',
          exclusive: 'menu',
          hr: 'top',
          show: !this.isPartnerUser && isOwner,
          onClick: () => this.goShare()
        },
        ...editNavs,
        {
          icon: 'palette',
          title: '设计样式',
          tooltip: '修改作品封面、版式及插页等样式',
          to: '/books/' + this.bookId + '/design',
          show: editable,
          hr: 'top'
        },
        {
          icon: 'shopping-cart',
          title: '购买',
          variant: 'primary',
          to: '/books/' + this.bookId + '/buy',
          show: isOwner,
          tooltip: '购买作品的纸质版或电子版'
        },
        {
          icon: 'book',
          title: '我也做一本',
          variant: 'primary',
          [agentOpenid ? 'href' : 'to']: agentOpenid ? `/pages/agent-home/${agentOpenid}` : '/create?expand=1',
          target: agentOpenid ? '_blank' : '',
          show: !isOwner
        }
      ]
    },
    showBottomNav() {
      if (window.isAndroid && window.isApp) {
        return false
      }

      // noinspection RedundantIfStatementJS
      if (window.isIOS && window.isApp && isVerGreaterThan(window.appVersion, '1.0.0')) {
        return false
      }

      return true
    },
    section() {
      return this.$route.params.month ? 'inner' : 'cover'
    },
    firstMonth() {
      if (!this.months.length) {
        return null
      }
      if (this.book.bookType === 'blogbook') {
        return this.months && this.months[0]
      }
      return this.months.firstValid()
    },
    editMonth() {
      if (this.$route.params.month) {
        return this.$route.params.month
      }
      if (this.firstMonth) {
        return this.firstMonth.route
      }
      const now = new Date()
      return now.getFullYear() + ('0' + (now.getMonth() + 1)).slice(-2)
    },
    isEmpty() {
      return !this.book.chapters
    },
    isImporting() {
      if (!['wxbook', 'wbbook', 'bbsbook', 'qbbbook'].includes(this.book.bookType)) {
        // 仅这几类书需要导入数据
        return false
      }
      if (this.book.auth !== 'edit') {
        // 不是自己的书
        return false
      }
      if (this.book.finalized) {
        // 已定稿的书
        return false
      }
      // 创建15分钟以内
      return this.$day().diff(this.book.createTime, 'minute') < 15
    },
    catalogList() {
      const {currentPage, pageSize} = this.catalog
      const startAt = (currentPage - 1) * pageSize
      return this.months?.slice?.(startAt, startAt + pageSize)
    },
    pageNum() {
      if (this.book.bookType === 'blogbook') {
        return this.bookPages
      }
      return this.book.pages
    },
    typeset() {
      return this.book.type || this.$ss.getItem('typeset.' + this.$route.params.bookId)
    }
  },
  methods: {
    async onLoad() {
      try {
        if (this.book.activatedId && this.book.id !== this.book.activatedId) {
          const bookId = this.getBookId({...this.book, id: this.book.activatedId}, this.isAgentUser)
          return this.$router.replace(this.$route.fullPath.replace(
            this.$route.params.bookId,
            bookId
          ))
        }

        this.$ss.setItem(this.bookId, this.$route.params.month || '')

        if (this.book.agentOpenid) {
          if (this.book.bookType === 'blogbook' && !this.book.own) {
            this.$store.commit('setSource', 'agent')
          }
          if (this.book.bookType !== 'blogbook' && this.book.auth !== 'edit') {
            this.$store.commit('setSource', 'agent')
          }
        }

        if (this.book.type) {
          this.$ss.setItem('typeset.' + this.book.bookId, this.book.type)
        }

        if (this.book.bookType === 'diarybook' && !this.book.sourceTypeId) {
          // 从老微信书或app进入时可能会遗失 sourceTypeId 字段
          this.$store.commit('setError', new Error('作品缺少 sourceTypeId，请联系客服解决'))
          return
        }

        if (this.book.bookType === 'blogbook') {
          this.loadStatus++
          await this.fetchCatalog()
          if (!this.$route.params.month) {
            this.catalog.pages = await this.$ajax.fetchBlogbookCatalogPages({bid: this.bookParams.id})
          }
          this.loadStatus--
          if (this.book.isTypesetting) {
            this.loadStatus++
            await this.fetchBookUntilReady()
            await this.fetchCatalog()
            this.loadStatus--
          }
          this.handleRouteVariables()
          return
        }

        this.loadStatus++
        await this.updateMonths()
        this.loadStatus--

        if (this.book.needRepublish || this.book.needUpgrade) {
          this.loadStatus++
          await this.fetchBookUntilReady()
          await this.updateMonths()
          this.loadStatus--
        }

        this.handleRouteVariables()
      } catch (err) {
        this.onRouteDataLoadError(err)
      } finally {
        if (this.book.id) {
          this.$setTitle(this.book.title + ' / ' + this.book.pages + '页')
        }

        if (window.isWechat) {
          const shareContents = {
            wxbook: '我的朋友圈内容做成书啦，进来帮我点赞吧！',
            wbbook: '我的微博内容做成书啦，进来帮我点赞吧！',
            diarybook: '我的回忆做成书啦，进来帮我点赞吧！'
          }

          let link = 'https://weixinshu.com/book-share/' + this.$route.params.bookId

          if (this.isAgent || this.isAgentUser || this.book.bookType === 'blogbook') {
            link = 'https://weixinshu.com/books/' + this.$route.params.bookId
          }

          configShare({
            title: `《${this.book.title}》| 微信书 - 把朋友圈一键做成书`,
            link: link,
            desc: shareContents[this.book.bookType] || shareContents.diarybook,
            imgUrl: this.bookCover
          })
        }
      }
    },
    async updateMonths() {
      this.months = await this.$ajax.fetchMonths({
        bookId: this.$route.params.bookId,
        all: this.book.auth === 'edit' && !this.book.finalized
      })
    },
    async goShare() {
      if (this.book.bookType !== 'blogbook') {
        this.$router.push('/books/' + this.book.bookId + '/share')
      } else {
        const link = 'https://weixinshu.com/book-share/' + this.book.bookId
        await copyText(link)
        this.$dialog.confirm({
          title: '分享作品',
          content: '链接已复制，请发送给好友',
          okTitle: '好的',
          okOnly: true
        })
      }
    },
    handleRouteVariables() {
      if (this.$route.hash) {
        setTimeout(() => {
          const el = this.$el.querySelector(this.$route.hash)
          if (el) {
            el.scrollIntoView()
          }
        }, 100)
      }
    },
    async fetchBookUntilReady() {
      const book = await this.fetchBook()
      if (!this || this._isDestroyed) {
        return
      }

      if (book.needRepublish || book.needUpgrade) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve(this.fetchBookUntilReady())
          }, 2000)
        })
      }
      this.book = book
      return book
    },
    async fetchCatalog() {
      const catalog = await this.$ajax.fetchBlogbookCatalog({bid: this.book.id})
      this.bookPages = catalog.bookPages
      this.catalog.totalPage = Math.ceil(catalog.length / this.catalog.pageSize)
      this.months = catalog.map(item => {
        item.hidden = false
        item.route = item.id
        item.value = item.title.slice(0, 5) + '...'
        delete item.author
        delete item.postDate
        delete item.id
        return item
      })
      const index = this.months.findIndex(i => i.route === this.$route.params.month)
      if (index > -1) {
        this.catalog.currentPage = Math.ceil((index + 1) / this.catalog.pageSize)
      }
    },
    goEdit(section) {
      if (!this.book.editable) {
        return
      }
      this.$router.push('/books/' + this.bookId + '/design/' + section)
    },
    goUpdate() {
      if (this.book.bookType !== 'blogbook') {
        this.$router.push('/books/' + this.bookId + '/update')
        return
      }

      if (this.book.sourceSite === 'weixinmp') {
        return this.$router.push('/wxmpassistant')
      }

      this.$req.post('/blogbook/crawler/crawl/', {sid: this.book.sid}).then(() => {
        this.$alert.success('已请求更新！请稍后刷新查看')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.book {
  max-width: 580px;
  $bg-height: 0;

  footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .page.add {
    height: auto;
    font-size: 2em;
    color: $primary;
    text-align: center;
    padding: 2em;
    cursor: pointer;
  }

  &[class*="A5"] {
    @media (min-width: 1400px) {
      &.inner {
        // 仅对内容部分生效
        max-width: 1150px;
      }
      ::v-deep .pages {
        @include clearfix();

        .page {
          float: left;
          margin: 2.5px;
        }
      }
    }
  }

  &[class*="A4"] {
    max-width: 820px;
  }

  ::v-deep .page {
    border: 1px solid $hr-border-color;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    margin-bottom: $grid-gutter-width-base / 2;
    background-color: #fff;
    box-shadow: $shadow-base;

    &.skeleton {
      padding: 48px;
      text-align: center;
      color: #999;
    }
  }

  ::v-deep .navigate-buttons {
    white-space: nowrap;
    padding: 1rem 0;
    flex-wrap: nowrap;
  }

  .catalog {
    position: absolute;
    width: 7.5em;
    margin-left: -8.25em;
    text-align: right;
    z-index: 5;
  }
}

#catalog {
  footer {
    align-items: center;
    width: 100%;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

::v-deep .page:hover .mask {
  opacity: 1;
}

::v-deep .mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .3);
  opacity: 0;
  transition: opacity .3s;
}

.dimmer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .3);
}
</style>
