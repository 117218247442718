export default function (...variables) {
  if (Array.isArray(variables[0])) {
    variables = variables[0]
  }
  const computed = {}
  for (const item of variables) {
    computed[item] = {
      get() {
        return this.$parent[item]
      },
      set(val) {
        this.$parent[item] = val
      }
    }
  }
  return {computed}
}
